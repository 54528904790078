import React, { Component } from 'react';
import Footer from '../componet/Footer';
import ScrollToTopOnMount from '../componet/ScrollToTopOnMount'
import Hero from '../componet/Hero';
import './About.css';
import intl from 'react-intl-universal';

class About extends Component {

    render() {
        return (
            <div className="about row">
                <ScrollToTopOnMount />
                <Hero
                    path={this.props.path} />

                <div className="content col-xs-12 center-xs row" style={{"backgroundColor": this.props.backgroundColor,"color": this.props.fontColor}}>
                    <div className="section-content row col-xs center-xs">
                        <div className="section col-xs-12 col-md-6 start-xs">
                            <div className="image" style={{ backgroundImage: 'url("img/about_description_image.webp")' }} />
                        </div>
                        <div className="section col-xs-12 col-md-6 first-xs start-xs">
                            <p className="title">{intl.get('about_content_title')}</p>
                            <p className="description">{intl.getHTML('about_content_description_1')}</p>
                        </div>
                        <div className="section col-xs-12 col-md-6 start-xs">
                            <p className="description">{intl.getHTML('about_content_description_2')}</p>
                        </div>
                        <div className="logo-container section col-xs-12 col-md-6 center-xs row middle-xs">
                            <img src={"img/artigiano_eccellente.png"} srcSet={"img/artigiano_eccellente@2x.png 2x, img/artigiano_eccellente@3x.png 3x"} alt="artigiano eccellente" />
                            <img src={"img/itin_art.png"} srcSet={"img/itin_art@2x.png 2x, img/itin_art@3x.png 3x"} alt="itin art" />
                            <img src={"img/certification_3.jpeg"} style={{width: "300px"}} alt="certificazione #3" />
                        </div>
                    </div>
                </div>
                <Footer style={{ fontColor: this.props.fontColor, backgroundColor: this.props.backgroundColor, appendImg: "_white" }} />
            </div>
        );
    }
}

export default About;