import React, { Component } from 'react';
import './App.css';
import intl from 'react-intl-universal';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contacts from './pages/Contacts';
import Header from './componet/Header';
import Loading from './componet/Loading';
import Product from './pages/Product';
import 'flexboxgrid/dist/flexboxgrid.min.css';

require('intl/locale-data/jsonp/it.js');

const locales = {
    "it-IT": require('./locales/it-IT.json'),
};

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            currentLocale: "it-IT"
        }
    }

    componentDidMount() {
        intl.init({
            currentLocale: locales[navigator.language || navigator.userLanguage] != null ? navigator.language || navigator.userLanguage : "it-IT",
            locales,
        }).then(() => {
            setTimeout(() => {
                this.setState({ isLoading: false })
            }, 500);
        });
    }

    componentDidUpdate() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <HashRouter>
                <Loading isLoading={this.state.isLoading} />
                <div className="content">
                    <Header />
                    <Switch>
                        <Route exact path="/"> 
                            <Redirect to="/home" />
                        </Route>
                        <Route exact path="/home" component={() => <Home
                            path={'home'}
                            fontColor= "#FFFFFF"
                            backgroundColor= "#05151f" />
                        } />
                        <Route exact path="/about" component={() => <About
                            path={'about'}
                            fontColor= "#FFFFFF"
                            backgroundColor= "#05151f" />
                        } />
                        <Route exact path="/contacts" component={() => <Contacts
                            path={'contacts'}
                            fontColor= "#FFFFFF"
                            backgroundColor= "#05151f" />
                        } />
                        <Route exact path="/external_env_1" component={() => <Product
                            path={'external_env_1'}
                            fontColor= "#FFFFFF"
                            backgroundColor= "#05151f"
                            heroBottomImages={['logo_arquati_white']} />
                        } />
                        <Route exact path="/external_env_2" component={() => <Product
                            path={'external_env_2'}
                            fontColor= "#FFFFFF"
                            backgroundColor= "#05151f"
                            heroBottomImages={['logo_arquati_white']} />
                        } />
                        <Route exact path="/external_env_3" component={() => <Product
                            path={'external_env_3'}
                            fontColor= "#FFFFFF"
                            backgroundColor= "#05151f" />
                        } />
                        <Route exact path="/external_env_4" component={() => <Product
                            path={'external_env_4'}
                            fontColor= "#FFFFFF"
                            backgroundColor= "#05151f" />
                        } />
                        <Route exact path="/internal_env_1" component={() => <Product
                            path={'internal_env_1'}
                            fontColor= "#FFFFFF"
                            backgroundColor= "#05151f"/>
                        } />
                        <Route exact path="/internal_env_2" component={() => <Product
                            path={'internal_env_2'}
                            fontColor= "#FFFFFF"
                            backgroundColor= "#05151f" />
                        } />
                        <Route exact path="/internal_env_3" component={() => <Product
                            path={'internal_env_3'}
                            fontColor= "#FFFFFF"
                            backgroundColor= "#05151f" />
                        } />
                        <Route exact path="/internal_env_4" component={() => <Product
                            path={'internal_env_4'}
                            fontColor= "#FFFFFF"
                            backgroundColor= "#05151f"/>
                        } />
                        {/* page not found */}
                        <Route component={() => <Home
                            path={'home'}
                            fontColor= "#FFFFFF"
                            backgroundColor= "#05151f" />
                        } />
                    </Switch>
                </div>
            </HashRouter>
        )
    }
}

export default App;
