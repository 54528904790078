import React, { Component } from 'react';
import Footer from '../componet/Footer';
import './Home.css'
import Hero from '../componet/Hero';
import ScrollToTopOnMount from '../componet/ScrollToTopOnMount';
import intl from 'react-intl-universal';
import { NavLink } from 'react-router-dom';

class Home extends Component {

    render() {
        this.externalItems = []
        for (var i = 0; i < 4; i++) {
            this.externalItems.push(
                <li className="listItem">
                    <NavLink to={"/external_env_" + (i + 1)}>
                        <div id={'external_env_' + (i + 1) + '_hero'} className="image" style={{backgroundImage: 'url("img/external_env_' + (i + 1) + '_hero.webp")'}} />
                    </NavLink>
                    <p className="title">{intl.get('external_env_' + (i + 1) + '_hero_title')}</p>
                    <p className="subtitle">{intl.get('external_env_' + (i + 1) + '_hero_subtitle')}</p>
                </li>

            );
        }
        this.internalItems = []
        for (var i = 0; i < 4; i++) {
            this.internalItems.push(
                <li className="listItem">
                    <NavLink to={"/internal_env_" + (i + 1)}>
                        <div id={'internal_env_' + (i + 1) + '_hero'} className="image" style={{backgroundImage: 'url("img/internal_env_' + (i + 1) + '_hero.webp")'}} />
                    </NavLink>
                    <p className="title">{intl.get('internal_env_' + (i + 1) + '_hero_title')}</p>
                    <p className="subtitle">{intl.get('internal_env_' + (i + 1) + '_hero_subtitle')}</p>
                </li>
            );
        }

        return (
            <div className="home row">
                <ScrollToTopOnMount />
                <Hero
                    path={this.props.path}
                    heroBottomImages={['logo_arquati_white']} />

                <div className="content row" style={{"backgroundColor": this.props.backgroundColor,"color": this.props.fontColor}}>
                    <p className="title start-xs center-sm col-sm-8 col-sm-offset-2">{intl.get('we_are_craftman')}</p>
                    <p className="subtitle start-xs center-sm col-sm-8 col-sm-offset-2">{intl.get('externals_desc')}</p>
                    <ul className="list">
                        {this.externalItems}
                    </ul>
                    <p className="subtitle start-xs center-sm col-sm-8 col-sm-offset-2">{intl.get('internals_desc')}</p>
                    <ul className="list">
                        {this.internalItems}
                    </ul>
                </div>

                <Footer style={{ fontColor: this.props.fontColor, backgroundColor: this.props.backgroundColor, appendImg: "_white" }} />
            </div>
        );
    }
}

export default Home;