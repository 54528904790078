import React, { Component } from 'react';
import intl from 'react-intl-universal';
import './Footer.css'

class Footer extends Component {

    constructor(props) {
        super(props)

        this.onEmailClick = this.onEmailClick.bind(this)
        this.onPhoneClick = this.onPhoneClick.bind(this)
        this.onMapClick = this.onMapClick.bind(this)
    }

    onEmailClick() {
        var email = intl.get("email");
        var subject = 'Richiesta info';
        document.location = "mailto:" + email + "?subject=" + subject;
    }

    onPhoneClick() {
        document.location = "tel:" + intl.get('phone_number')
    }

    onMapClick() {
        window.open(intl.get('address_url'), '_blank')
    }

    render() {
        return (
            <div className="footer row" style={{
                "backgroundColor": this.props.style.backgroundColor,
                "color": this.props.style.fontColor
            }}>
                <div className="content row col-xs-12 center-xs">
                    <div className="sections-content row col-xs-12">
                        <div className="section start-xs col-xs-5 col-xs-offset-1 col-sm-4 col-sm-offset-0">
                            <p className="title">{intl.get('contacts')}</p>
                            <p className="pointer" onClick={this.onPhoneClick}>{intl.get("phone_number")}</p>
                            <p className="pointer truncate" onClick={this.onEmailClick}>{intl.get("email")}</p>
                            <p className="pointer" onClick={this.onMapClick}>{intl.getHTML("address")}</p>
                        </div>
                        <div className="section start-xs col-xs-5 col-sm-4 col-sm-offset-0">
                            <p className="title">{intl.get('times')}</p>
                            <p>{intl.get('weekdays')}<br />{intl.getHTML("opening_time_morning")}<br/>{intl.getHTML("opening_time_afternoon")}</p>
                            <p>{intl.get('saturday')}<br />{intl.getHTML("opening_time_morning")}<br />{intl.get('afternoon_schedule')}</p>
                        </div>
                        <div className="section start-xs col-xs-10 col-xs-offset-1 col-sm-4 col-sm-offset-0">
                            <p className="title">{intl.get('reseller')}</p>
                            <div className="row">
                                <div className="col-xs-12 image-container">
                                    <img src={"img/logo_arquati_small" + this.props.style.appendImg + ".png"} srcSet={"img/logo_arquati_small" + this.props.style.appendImg + "@2x.png 2x, img/logo_arquati_small" + this.props.style.appendImg + "@3x.png 3x"} alt="logo rivenditore" />
                                    <img src={"img/logo_velux" + this.props.style.appendImg + ".png"} srcSet={"img/logo_velux" + this.props.style.appendImg + "@2x.png 2x, img/logo_velux" + this.props.style.appendImg + "@3x.png 3x"} alt="logo rivenditore" />
                                </div>
                            </div>
                        </div>
                        <div className="section last start-xs col-xs-10 col-xs-offset-1 col-sm-4 col-sm-offset-0">
                            <p className="subtitle">{intl.get("piva")}</p>
                        </div>
                        <div className="section last start-xs col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-0">
                            <p className="subtitle">{intl.get('copy')}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;