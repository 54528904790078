import React, { Component } from 'react';
import './Hero.css'
import intl from 'react-intl-universal';

class Hero extends Component {

    render() {
        const images = this.props.heroBottomImages ? this.props.heroBottomImages.map((img) =>
            <img src={"img/" + img + ".png"} srcSet={"img/" + img + "@2x.png 2x, img/" + img + "@3x.png 3x"} alt="logo rivenditore partner" />
        ) : null

        return (
            <div className="hero" style={{ backgroundImage: 'url("img/' + this.props.path + '_hero.webp")' }}>
                <div className="mask backdrop-blur" />
                <div className="constraint-parent column center">
                    <p className="title">{intl.getHTML(this.props.path + '_hero_title')}</p>
                    <p className="subtitle">{intl.getHTML(this.props.path + '_hero_subtitle')}</p>
                </div>
                <div className={"constraint-parent column bottom" + (this.props.heroBottomImages ? '' : ' hidden')}>
                    <p className="message">{this.props.heroBottomMessage}</p>
                    <div className="logo-container row center-xs middle-xs">
                        {images}
                    </div>
                </div>
            </div>
        );
    }
}

export default Hero;