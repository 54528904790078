import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import intl from 'react-intl-universal';
import './Header.css'

class Header extends Component {

    constructor(props) {
        super(props);

        this.handleBurgerMenu = this.handleBurgerMenu.bind(this);
        this.closeBurgerMenu = this.closeBurgerMenu.bind(this);
        this.handleExternalsDropdownMenu = this.handleExternalsDropdownMenu.bind(this);
        this.handleInternalsDropdownMenu = this.handleInternalsDropdownMenu.bind(this);
        this.handleScroll = this.handleScroll.bind(this)

        this.state = {
            burgerMenu: false,
            externals: false,
            internals: false
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll(event) {
        this.setState({
          scrollY: window.scrollY
        });
    }

    handleBurgerMenu() {
        this.setState({
            burgerMenu: !this.state.burgerMenu,
            externals: false,
            internals: false
        });
    }

    closeBurgerMenu() {
        this.setState({
            burgerMenu: false,
            internals: false,
            externals: false
        });
    }

    handleExternalsDropdownMenu() {
        this.setState({
            externals: !this.state.externals,
            internals: false
        });
    }

    handleInternalsDropdownMenu() {
        this.setState({
            internals: !this.state.internals,
            externals: false
        });
    }

    render() {
        return (
            <div className={"header" + (this.state.scrollY > 10 ? ' scrolling' : '')}>
                <div className="menu-bar row">
                    <div className="logo col-xs-6 col-md-3">
                        <NavLink to="/home">
                            <img src="img/logo.png" alt="logo" onClick={this.closeBurgerMenu} />
                        </NavLink>
                    </div>
                    <div className="navigation row col-md-6 around-md middle-md">
                        <NavLink to="/home"><p className="section" onClick={this.closeBurgerMenu}>{intl.get('home')}</p></NavLink>
                        <p className={"section" + (this.state.burgerMenu ? ' active' : '')} onClick={this.handleBurgerMenu}>{intl.get('our_works')}<i className={"arrow down" + (this.state.burgerMenu ? ' open' : '')} /></p>
                        <div className={"submenu row" + (this.state.burgerMenu ? ' open' : '')}>
                            <p className="section title col-md-8 col-md-offset-2">{intl.get('externals_env')}</p>
                            <div className="col-md-2 col-md-offset-2"><NavLink to="/external_env_1"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('external_env_1_hero_title')}</p></NavLink></div>
                            <div className="col-md-2"><NavLink to="/external_env_2"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('external_env_2_hero_title')}</p></NavLink></div>
                            <div className="col-md-2"><NavLink to="/external_env_3"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('external_env_3_hero_title')}</p></NavLink></div>
                            <div className="col-md-2"><NavLink to="/external_env_4"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('external_env_4_hero_title')}</p></NavLink></div>
                            <p className="section title col-md-8 col-md-offset-2">{intl.get('internals_env')}</p>
                            <div className="col-md-2 col-md-offset-2"><NavLink to="/internal_env_1"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('internal_env_1_hero_title')}</p></NavLink></div>
                            <div className="col-md-2"><NavLink to="/internal_env_2"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('internal_env_2_hero_title')}</p></NavLink></div>
                            <div className="col-md-2"><NavLink to="/internal_env_3"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('internal_env_3_hero_title')}</p></NavLink></div>
                            <div className="col-md-2"><NavLink to="/internal_env_4"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('internal_env_4_hero_title')}</p></NavLink></div>
                        </div>
                        <NavLink to="/about"><p className="section" onClick={this.closeBurgerMenu}>{intl.get('about')}</p></NavLink>
                        <NavLink to="/contacts"><p className="section" onClick={this.closeBurgerMenu}>{intl.get('work_with_us')}</p></NavLink>
                    </div>
                    <div className="menu-icon row middle-xs end-xs col-xs-6 col-md-2 col-md-offset-10">
                        <div className={"burger burger-rotate" + (this.state.burgerMenu ? ' open' : '')} onClick={this.handleBurgerMenu}>
                            <div className="burger-lines"></div>
                        </div>
                    </div>
                </div>
                <div className={"menu-content navigation" + (this.state.burgerMenu ? ' open' : '')}>
                    <p className={"section" + (this.state.externals ? ' active' : '')} onClick={this.handleExternalsDropdownMenu}>{intl.get('externals_env')}<i className={"arrow down" + (this.state.externals ? ' open' : '')} /></p>
                    <div className={"submenu center-xs" + (this.state.externals ? ' open' : '')}>
                        <NavLink to="/external_env_1"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('external_env_1_hero_title')}</p></NavLink>
                        <NavLink to="/external_env_2"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('external_env_2_hero_title')}</p></NavLink>
                        <NavLink to="/external_env_3"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('external_env_3_hero_title')}</p></NavLink>
                        <NavLink to="/external_env_4"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('external_env_4_hero_title')}</p></NavLink>
                    </div>
                    <p className={"section" + (this.state.internals ? ' active' : '')} onClick={this.handleInternalsDropdownMenu}>{intl.get('internals_env')}<i className={"arrow down" + (this.state.internals ? ' open' : '')} /></p>
                    <div className={"submenu center-xs" + (this.state.internals ? ' open' : '')}>
                        <NavLink to="/internal_env_1"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('internal_env_1_hero_title')}</p></NavLink>
                        <NavLink to="/internal_env_2"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('internal_env_2_hero_title')}</p></NavLink>
                        <NavLink to="/internal_env_3"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('internal_env_3_hero_title')}</p></NavLink>
                        <NavLink to="/internal_env_4"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('internal_env_4_hero_title')}</p></NavLink>
                    </div>
                    <NavLink to="/about"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('about')}</p></NavLink>
                    <NavLink to="/contacts"><p className="section" onClick={this.handleBurgerMenu}>{intl.get('contacts')}</p></NavLink>
                </div>
            </div>
        );
    }
}

export default Header;