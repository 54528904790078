import React, { Component } from 'react';
import ScrollToTopOnMount from '../componet/ScrollToTopOnMount'
import Hero from '../componet/Hero';
import * as firebase from "firebase/app";
import './Contacts.css';
import intl from 'react-intl-universal';
import "firebase/functions";

class Contacts extends Component {

    constructor(props) {
        super(props)

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.state = {
            contactsForm: {
                name: "",
                email: "",
                text: "",
                status: false
            }
        }
    }

    onEmailClick() {
        var email = intl.get("email");
        var subject = 'Richiesta info';
        document.location = "mailto:" + email + "?subject=" + subject;
    }

    onPhoneClick() {
        document.location = "tel:" + intl.get('phone_number')
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            contactsForm: {
                status: true
            }
        })

        var sendEmailRequest = firebase.functions().httpsCallable('sendEmail');
        sendEmailRequest({
            name: this.state.contactsForm.name,
            email: this.state.contactsForm.email,
            text: this.state.contactsForm.text
        }).then(function (result) {
            // Read result of the Cloud Function.
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        var data = this.state.contactsForm
        data[name] = value

        this.setState({
            contactsForm: data
        });
    }

    render() {
        return (
            <div className="contacts row">
                <ScrollToTopOnMount />
                <Hero
                    path={this.props.path} />

                <div className="content col-xs-12 center-xs row" style={{"backgroundColor": this.props.backgroundColor,"color": this.props.fontColor}}>
                    <div className="section-content row col-xs center-xs">
                        <div className="section col-xs-12 col-md-6 start-xs">
                            <p className="description">{intl.getHTML('contacts_content_description')}</p>
                        </div>
                        <div className="section col-xs-12 col-md-6 start-xs">
                            <p className="pointer title" onClick={this.onPhoneClick}>{intl.get("phone_number")}</p>
                            <p className="pointer title black center-xs" onClick={this.onEmailClick}>{intl.get("email")}</p>
                        </div>
                        <div className="section col-xs-12 col-md-6 start-xs">
                            <p className="subtitle">{intl.getHTML('our_times')}</p><br />
                            <p className="description">{intl.getHTML('weekdays')}</p>
                            <p className="description">{intl.getHTML('opening_time_morning')}<br />{intl.getHTML('opening_time_afternoon')}</p><br /><br />
                            <p className="description">{intl.getHTML('saturday')}</p>
                            <p className="description">{intl.getHTML('opening_time_morning')}</p>
                            <p className="description">{intl.getHTML('afternoon_schedule')}</p>
                            <br /><br /><br />
                            <p className="subtitle">{intl.getHTML('were_are_we')}</p><br />
                            <p className="description">{intl.getHTML('address')}</p><br />
                            <a className="description" href={intl.get('gmap_url')} target="_blank" rel="noopener noreferrer">{intl.getHTML('directions')}</a>
                        </div>
                        <div className="section col-xs-12 col-md-6 start-xs">
                            <p className="subtitle">{intl.getHTML('ask_for_info')}</p><br />
                            <form onSubmit={this.handleSubmit}>
                                <label>
                                    <p>{intl.getHTML('name')}<sup>{intl.get('mandatory_symbol')}</sup></p>
                                    <input type="text" name="name" value={this.state.contactsForm.name} onChange={this.handleInputChange} required />
                                </label>
                                <label>
                                    <p>{intl.getHTML('email_from')}<sup>{intl.get('mandatory_symbol')}</sup></p>
                                    <input type="text" name="email" value={this.state.contactsForm.email} onChange={this.handleInputChange} required />
                                </label>
                                <label>
                                    <p>{intl.getHTML('write_us')}<sup>{intl.get('mandatory_symbol')}</sup></p>
                                    <textarea rows="6" maxLength="500" name="text" value={this.state.contactsForm.text} onChange={this.handleInputChange} required />
                                </label>
                                <input type="submit" value={intl.get('send')} style={{ display: this.state.contactsForm.status ? "none" : "block" }} />
                                <p className="description form success" style={{ display: this.state.contactsForm.status ? "block" : "none" }}>{intl.get('email_sent')}</p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contacts;