import React, { Component } from 'react';
import Footer from '../componet/Footer';
import './Product.css'
import Hero from '../componet/Hero';
import intl from 'react-intl-universal';
import ScrollToTopOnMount from '../componet/ScrollToTopOnMount'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Product extends Component {

    constructor(props) {
        super(props);

        this.handleCarousel = this.handleCarousel.bind(this)

        this.state = {
            carousel: {
                visible: false
            }
        }

        this.state.carouselSettings = {
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 2,
            slidesPerRow: 3,
            infinite: false,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        rows: 1,
                        slidesPerRow: 1,
                        slidesToShow: 1.5,
                        arrows: false
                    }
                }
            ]
        };

        this.state.carouselFullscreenSettings = {
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            lazyLoad: true
        };
    }

    handleCarousel(i) {
        this.fullscreenSlider.slickGoTo(i, true)

        this.setState({
            carousel: {
                visible: !this.state.carousel.visible
            }
        })
    }

    render() {
        var images = require.context('../../public/img/', false, /(.webp)$/).keys()
        var path = this.props.path
        images = images.filter(function (el) {
            return el.indexOf(path + "_media_") !== -1
        })

        images.sort(function (a, b) {
            var aValue = a.substring(a.lastIndexOf("media_") + 6, a.lastIndexOf("."))
            var bValue = b.substring(b.lastIndexOf("media_") + 6, b.lastIndexOf("."))
            return aValue - bValue
        })

        this.galleryPhotos = []
        this.carouselPhotos = []
        for (var i = 0; i < images.length; i++) {
            var image = images[i]
            image = image.replace("./", "")

            var url = 'img/' + image
            this.galleryPhotos.push(
                <div>
                    <div
                        id={image.replace(".webp", "")}
                        className="carousel-image"
                        onClick={this.handleCarousel.bind(this, i)}
                        style={{
                            'backgroundImage': 'url(' + url + ')'
                        }} />
                </div>
            );

            this.carouselPhotos.push(
                <div>
                    <img class="carousel-image-item" src={url} alt="immagine del carousel fullscreen" />
                </div>
            );
        }

        return (
            <div className="product row">
                <ScrollToTopOnMount />
                <Hero
                    path={this.props.path}
                    heroBottomMessage={this.props.heroBottomMessage}
                    heroBottomImages={this.props.heroBottomImages} />

                <div className="content row col-xs-12 center-xs" style={{ "backgroundColor": this.props.backgroundColor, "color": this.props.fontColor }}>
                    <div className="first-content col-xs-12 col-sm-6 first-sm">
                        <div className="row center-xs">
                            <div className="col-xs-12 row center-xs">
                                <p className="message start-xs">{intl.getHTML(this.props.path + '_product_message_2')}</p>
                            </div>
                            <div className="image-container col-xs-12 first-sm">
                                <div id={this.props.path + '_product_image_1'} className="image" style={{ backgroundImage: 'url("img/' + this.props.path + '_product_image_1.webp")' }} />
                            </div>
                        </div>
                    </div>
                    <div className="first-content col-xs-12 col-sm-6 first-xs">
                        <div className="row center-xs">
                            <div className="col-xs-12 row center-xs">
                                <p className="message start-xs">{intl.getHTML(this.props.path + '_product_message_1')}</p>
                            </div>
                            <div className="image-container col-xs-12">
                                <div id={this.props.path + '_product_image_2'} className="image" style={{ backgroundImage: 'url("img/' + this.props.path + '_product_image_2.webp")' }} />
                            </div>
                        </div>
                    </div>

                    {this.props.path === 'external_env_1' ?
                        <div className="logo-container col-xs-12 around-xs">
                            <img src="img/logo_somfy_original.png" srcSet={"img/logo_somfy_original@2x.png 2x, img/logo_somfy_original@3x.png 3x"} alt="logo rivenditore" />
                            <img src="img/logo_nice_original.png" srcSet={"img/logo_nice_original@2x.png 2x, img/logo_nice_original@3x.png 3x"} alt="logo rivenditore" />
                            <img src="img/logo_cherubini_original.png" srcSet={"img/logo_cherubini_original@2x.png 2x, img/logo_cherubini_original@3x.png 3x"} alt="logo rivenditore" />
                            <img src="img/logo_asa_original.png" srcSet={"img/logo_asa_original@2x.png 2x, img/logo_asa_original@3x.png 3x"} alt="logo rivenditore" />
                            <img src="img/logo_ti_original.png" srcSet={"img/logo_ti_original@2x.png 2x, img/logo_ti_original@3x.png 3x"} alt="logo rivenditore" />
                        </div> : null
                    }

                    <div className="gallery row">
                        <p className="title col-xs-12">{intl.getHTML('products_gallery_title')}</p>
                        <div className="carousel-container col-xs-12">
                            <Slider {...this.state.carouselSettings}>
                                {this.galleryPhotos}
                            </Slider>
                        </div>
                        <div className={"carousel-fullscreen-container mask backdrop-blur" + (this.state.carousel.visible ? ' visible' : '')}>
                            <img className="exit-icon" src="img/exit_icon.png" alt="exit" onClick={this.handleCarousel} style={{ cursor: 'pointer' }} />
                            <Slider ref={slider => (this.fullscreenSlider = slider)} {...this.state.carouselFullscreenSettings}>
                                {this.carouselPhotos}
                            </Slider>
                        </div>
                    </div>


                </div>

                <Footer style={{ fontColor: this.props.fontColor, backgroundColor: this.props.backgroundColor, appendImg: "_white" }} />
            </div>
        );
    }
}

export default Product;