import * as firebase from "firebase/app";
import "firebase/performance";
import "firebase/functions";

var firebaseConfig = {
    apiKey: "AIzaSyCatt3C4jkFeYBuyrAAKt5Xa3yNZKD0B-Y",
    authDomain: "crippatappezziere.firebaseapp.com",
    databaseURL: "https://crippatappezziere.firebaseio.com",
    projectId: "crippatappezziere",
    storageBucket: "crippatappezziere.appspot.com",
    messagingSenderId: "678554687311",
    appId: "1:678554687311:web:aca0dbca7e7e4901c8973a",
    measurementId: "G-9WD8G2TW81"
}

firebase.initializeApp(firebaseConfig)
firebase.performance()
firebase.functions()